
.text-field {
    display: flex;
    flex-direction: column;
    position: relative;
}

.empty-text-field input {
    background-color: #EFEFEF !important;
    border: 1px solid transparent !important;
}

.text-field label {
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    color: #121212;
    margin-bottom: 4px;
}

.text-field input {
    background-color: transparent;
    border: 1px solid #C1BFB0;
    border-radius: 6px;
    height: 50px;
    padding: 0 15px;
}

.text-field .mandatory {
    color: #FDAE37;
}

.text-field .error {
    position: absolute;
    bottom: -16px;
    font-size:11px;
    color: #c30000;
}

.text-field input::placeholder {
    color: #313131;
    opacity: 0.6;
}