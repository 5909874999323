.btn {
    position: relative;
    border-radius: 8px;
    white-space: nowrap;
}

.btn button {
    outline: none;
    border-radius: 8px;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 600;
    border: none;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
}

.btn button:hover {
    cursor: pointer;
}

/**
    Color
 */
.btn-primary button {
    background-color: #FDAE37;
    color: #1F1F1F;
}

.btn-secondary button {
    background-color: #414141;
}

.btn-secondary button:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #414141;

}

.btn-secondary.btn-selected {
    color: #FDAE37;
}

.btn-neutral button {
    background-color: #EFEFEF;
    color: #414141;
}

.btn-neutral button:hover {
    background-color: #dcdbdb;
}

.btn-transparent button {
    background-color: transparent;
    color: #414141;
    border: 1px solid #000000;
}

.btn-transparent button:hover {
    /*background-color: rgba(189, 189, 189, 0.2);*/
    color: #616161;
    border: 1px solid #616161;
}

.btn-transparent button:hover img {
    filter: invert(40%) sepia(11%) saturate(15%) hue-rotate(320deg) brightness(88%) contrast(87%);
}

.btn-submit button {
    background-color: #E0DED4;
}

.btn-white button {
    background-color: #FFFFFF;
    color: #1F1F1F;

}

.btn-white button:hover {
    background-color: #f1f1f1;
}

.btn-red button{
    background-color: #DE5242;
    color: #FFFFFF;
}

.btn-red button:hover {
    background-color: #ec6557;
}

.btn-orange button {
    background-color: #FC634B;
    color: #FFFFFF;
}

.btn-orange button:hover {
    background: #FC634B linear-gradient(90deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
}

.btn-orange img {
    filter: invert();
}

.btn-green button {
    background-color: #40BA22;
    color: #FFFFFF;
}

.btn-green button:hover {
    background: #40ba22 linear-gradient(90deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
}

.btn-green img {
    filter: invert();
}

.btn-blue button {
    background-color: rgb(209, 228, 236);
    color: #313131;
}

.btn-blue button:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(0, 103, 136, 0.15);
}


.btn-black button {
    background-color: #000000;
    color: #FFFFFF;
}

/**
    Size
 */

.btn-small button {
    padding: 3px 9px 3px 9px;
    font-size: 11px;
}

.btn-medium button {
    padding: 6px 12px 6px 12px;
    font-size: 12px;
}

.btn-large .btn-text {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
}

.btn-large button {
    padding: 0 12px;
}

/**
    Icon
 */

.btn-start-icon {
    margin-left: 2px;
    display: flex;
    align-items: center;
}

.btn-end-icon {
    margin-right: 2px;
    display: flex;
    align-items: center;
}

.btn-disabled button {
    background-color: #DBDBDB !important;
    color: #9A9A9A !important;
}

.btn-disabled img {
    filter: invert(64%) sepia(0%) saturate(468%) hue-rotate(169deg) brightness(96%) contrast(86%) !important;
}