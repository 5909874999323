
.SubscriptionCard {
    border-radius: 12px;
    flex-basis: 242px;
    max-width: 242px;
    height: fit-content;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SubscriptionCard .basic {
    background-color: #F6F5F3;
    padding: 16px;
    border-radius: 12px;
}

.SubscriptionCard .standard {
    background-color: rgba(253, 174, 55, 0.24);
    padding: 16px;
    border-radius: 12px;
}

.SubscriptionCard .premium {
    background-color: #1F1F1F;
    padding: 16px;
    border-radius: 12px;
}


.SubscriptionCard.selected {
}


.SubscriptionCard .description {
    display: none;
}

.SubscriptionCard.expanded .description {
    display: block;
}

.SubscriptionCard.expanded .body {
    justify-content: left !important;
}

.SubscriptionCard.expanded .body img {
    width: 30%;
}


.SubscriptionCard .body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SubscriptionCard .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.SubscriptionCard .description {
    font-weight: 400;
    font-size: 12px;
    color: #1F1F1F;
    margin-top: 20px;
}

.SubscriptionCard .description ul {
    font-weight: 500;
    font-size: 12px;
    margin-left: 0;
    padding-left: 10px;
}

.SubscriptionCard .description ul li {
    margin-bottom: 10px;
}

.SubscriptionCard .img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SubscriptionCard .basic > .img img {
    position: absolute;
    top: -40px;
}

.SubscriptionCard .standard > .img img {
    position: absolute;
    top: -50px;
}

.SubscriptionCard .premium > .img img {
    position: absolute;
    top: -60px;
}

.SubscriptionCard .title {
    margin-top: 24px;
    font-size: 16px;
    text-align: center;
    font-family: Inter-Medium;
}

.SubscriptionCard .sub-title {
    font-size: 14px;
    text-align: center;
    margin-top: 8px;
}

.SubscriptionCard .price {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
}

.SubscriptionCard .price > div:nth-child(1) {
    font-size: 14px;
    font-family: Roboto-Medium;
    opacity: 0.3;
}

.SubscriptionCard .price > div:nth-child(2) {
    font-size: 28px;
    line-height: 28px;
    margin-left: 5px;
    font-family: Roboto-Medium;
}