
.SubscriptionSelect {
    position: relative;
}

.SubscriptionSelect > .select {
    background-color: #5A5A5A;
    border-radius: 6px;
    display: flex;
    align-items: baseline;
    padding: 12px 15px;
    position: relative;
}

.SubscriptionSelect > .select:hover {
    cursor: pointer;
}


.SubscriptionSelect > .select > .label {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
}

.SubscriptionSelect > .select > .price {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 40px;
}

.SubscriptionSelect > .options {
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 4px 52px 0px rgba(0, 0, 0, 0.13);
    z-index: 2;
    overflow: hidden;
    padding: 5px 0px;
}

.SubscriptionSelect > .options > .option {
    color: #313131;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    padding: 0 15px;
}

.SubscriptionSelect > .options > .option:hover {
    cursor: pointer;
    background-color: #d9d9d9;
}

.SubscriptionSelect > .options > .option.selected {
    background-color: #EFEFEF;
}

.SubscriptionSelect .select .caret {
    position: absolute;
    right: 20px;
    top: 20px;
}