
.text-area-field {
    /*display: flex;*/
    /*flex-direction: column;*/
}

.empty-text-area-field textarea {
    background-color: #EFEFEF !important;
    border: none !important;
}

.text-area-field label {
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    color: #121212;
    margin-bottom: 8px;
}

.text-area-field textarea {
    background-color: transparent;
    border: 1px solid #C1BFB0;
    border-radius: 6px;
    padding: 15px 16px;
    resize: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.text-area-field textarea::placeholder {
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.6;
}