
.StepItem {
    display: flex;
    flex-direction: column;
    width: 230px;
    position: relative;
    height: 90px;
    color: #313131;
}

.StepItem .content {
    display: flex;
    align-items: start;
}

.StepItem .content > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.StepItem .icon {
    background-color: #DFDFDF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-clip: content-box;
    padding: 2px;
}

.StepItem:not(.completed) img {
    filter: invert(71%) sepia(2%) saturate(50%) hue-rotate(314deg) brightness(93%) contrast(82%)
}

.StepItem .icon-selected {
    background-color: #FC634B;
}

.StepItem .icon-selected img {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(151deg) brightness(102%) contrast(101%);
}

.StepItem .sub-label {
    font-size: 14px;
    font-weight: 400;
}

.StepItem .label {
    font-size: 18px;
    font-weight: 500;
}

.StepItem .line {
    position: absolute;
    top: 10px;
    bottom: -34px;
    width: 40px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.StepItem .line > div {
    width: 2px;
    background-color: #FFFFFF;
    height: 23px;
}

.StepItem.completed .icon {
    background-color: #FFFFFF;
}