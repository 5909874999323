.Tooltip {

}

.Tooltip .title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
}

.Tooltip .description {
    border: 2px solid #3C93AE;
    background-color: #EEFBFF;
    border-radius: 8px;
    padding: 24px;
    font-size: 14px;
    color: #313131;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
    margin-bottom: 15px;
    align-items: center;
}

.Tooltip .description > div:first-child {
    padding-right: 40px;
}

.Tooltip .arrow {
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    border-right: 2px solid #3C93AE;
    border-bottom: 2px solid #3C93AE;
    background-color: #EEFBFF;
    border-bottom-right-radius: 3px;
    z-index: 1;
    position: absolute;
    left: 4px;
    top: -24px;
}

.Tooltip .icon-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
}

.Tooltip .icon-wrapper:hover {
    cursor: pointer;
}