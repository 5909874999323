
.IngredientForm {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
}

.IngredientForm table {
    /*table-layout: fixed;*/
    width: 100%;
    margin-bottom: 10px;
}

.IngredientForm table tbody tr {
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
}


.IngredientForm table th {
    font-size: 14px;
    color: #313131;
    font-weight: 400;
}

.IngredientForm table th .mandatory {
    color: #FDAE37;
}

.IngredientForm table th img {
    vertical-align: middle;
}

@media (min-width: 500px) {

    .IngredientForm table th, td {
        padding: 12px 20px 12px 0;
    }

}