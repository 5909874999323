
.UnitSelect {
    position: relative;
    display: flex;
    flex-direction: column;
}

.UnitSelect select {
    background-color: #EFEFEF !important;
    border: 1px solid transparent !important;

    border-radius: 8px;
    height: 50px;
    padding: 0 25px 0 15px;
    /* [Firefox][Chrome] No arrow  */
    appearance: none;
    width: 100%;

    color: #313131;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}


/*[IE] No arrow*/
.UnitSelect select::-ms-expand {
    display: none;
}

.UnitSelect img {
    position: absolute;
    bottom: 16px;
    right: 10px;
    filter: invert(1);
}

.UnitSelect label {
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    color: #121212;
    margin-bottom: 4px;
}