
.Form {
    display: grid;
    grid-template-columns: 2fr 280px  repeat(9, 1fr) 2fr;
    background-color: #EFEFEF;
    min-height: 100vh;
    padding-bottom: 100px;
}

.Form .status {

}

.Form .content {

}

.Form > .title {
    grid-column: 2/12;
    font-size: 20px;
    padding: 4px;
    margin-top: 10px;
}

@media (min-width: 500px) {
    .Form > .title {
        grid-column: 2/12;
        font-size: 30px;
        padding: 20px;
        margin-top: 20px;
    }
}